/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { HmnzTsMdev } from '../models/hmnz-ts-mdev';
import { TsMdevFilters } from '../models/ts-mdev-filters';

@Injectable({ providedIn: 'root' })
export class TsMdevResourceService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getTsMdevs()` */
  static readonly GetTsMdevsPath = '/api/resource/TsMdev';

  /**
   * Get TsMdev resources.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTsMdevs()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTsMdevs$Response(
    params?: {
      filters?: string;
      limit_page_length?: number;
      limit_start?: number;
      order_by?: string;
      or_filters?: string;
      fields?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
'data': Array<HmnzTsMdev>;
}>> {
    const rb = new RequestBuilder(this.rootUrl, TsMdevResourceService.GetTsMdevsPath, 'get');
    if (params) {
      rb.query('filters', params.filters, {});
      rb.query('limit_page_length', params.limit_page_length, {});
      rb.query('limit_start', params.limit_start, {});
      rb.query('order_by', params.order_by, {});
      rb.query('or_filters', params.or_filters, {});
      rb.query('fields', params.fields, {"style":"form"});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'data': Array<HmnzTsMdev>;
        }>;
      })
    );
  }

  /**
   * Get TsMdev resources.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTsMdevs$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTsMdevs(
    params?: {
      filters?: string;
      limit_page_length?: number;
      limit_start?: number;
      order_by?: string;
      or_filters?: string;
      fields?: string;
    },
    context?: HttpContext
  ): Observable<{
'data': Array<HmnzTsMdev>;
}> {
    return this.getTsMdevs$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data': Array<HmnzTsMdev>;
}>): {
'data': Array<HmnzTsMdev>;
} => r.body)
    );
  }

  /** Path part for operation `createTsMdev()` */
  static readonly CreateTsMdevPath = '/api/resource/TsMdev';

  /**
   * Create a new TsMdev resource.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createTsMdev()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTsMdev$Response(
    params: {
      body: HmnzTsMdev
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
'data': HmnzTsMdev;
}>> {
    const rb = new RequestBuilder(this.rootUrl, TsMdevResourceService.CreateTsMdevPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'data': HmnzTsMdev;
        }>;
      })
    );
  }

  /**
   * Create a new TsMdev resource.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createTsMdev$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTsMdev(
    params: {
      body: HmnzTsMdev
    },
    context?: HttpContext
  ): Observable<{
'data': HmnzTsMdev;
}> {
    return this.createTsMdev$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data': HmnzTsMdev;
}>): {
'data': HmnzTsMdev;
} => r.body)
    );
  }

  /** Path part for operation `patchTsMdev()` */
  static readonly PatchTsMdevPath = '/api/resource/TsMdev';

  /**
   * Patch TsMdev resources.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchTsMdev()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchTsMdev$Response(
    params: {
      filters?: TsMdevFilters;
      body: HmnzTsMdev
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<HmnzTsMdev>> {
    const rb = new RequestBuilder(this.rootUrl, TsMdevResourceService.PatchTsMdevPath, 'patch');
    if (params) {
      rb.query('filters', params.filters, {"style":"form"});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HmnzTsMdev>;
      })
    );
  }

  /**
   * Patch TsMdev resources.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchTsMdev$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchTsMdev(
    params: {
      filters?: TsMdevFilters;
      body: HmnzTsMdev
    },
    context?: HttpContext
  ): Observable<HmnzTsMdev> {
    return this.patchTsMdev$Response(params, context).pipe(
      map((r: StrictHttpResponse<HmnzTsMdev>): HmnzTsMdev => r.body)
    );
  }

  /** Path part for operation `updateTsMdev()` */
  static readonly UpdateTsMdevPath = '/api/resource/TsMdev/{name}';

  /**
   * Update TsMdev resources.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateTsMdev()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTsMdev$Response(
    params: {

    /**
     * The name of the TsMdev to update
     */
      name: string;
      body: HmnzTsMdev
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<HmnzTsMdev>> {
    const rb = new RequestBuilder(this.rootUrl, TsMdevResourceService.UpdateTsMdevPath, 'put');
    if (params) {
      rb.path('name', params.name, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HmnzTsMdev>;
      })
    );
  }

  /**
   * Update TsMdev resources.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateTsMdev$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTsMdev(
    params: {

    /**
     * The name of the TsMdev to update
     */
      name: string;
      body: HmnzTsMdev
    },
    context?: HttpContext
  ): Observable<HmnzTsMdev> {
    return this.updateTsMdev$Response(params, context).pipe(
      map((r: StrictHttpResponse<HmnzTsMdev>): HmnzTsMdev => r.body)
    );
  }

}
