/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { HmnzTelesalesEmployment } from '../models/hmnz-telesales-employment';

@Injectable({ providedIn: 'root' })
export class TelesalesEmploymentResourceService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getTelesalesEmployments()` */
  static readonly GetTelesalesEmploymentsPath = '/api/resource/TelesalesEmployment';

  /**
   * Get TelesalesEmployment resources.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTelesalesEmployments()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTelesalesEmployments$Response(
    params?: {
      filters?: string;
      limit_page_length?: number;
      limit_start?: number;
      order_by?: string;
      or_filters?: string;
      fields?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
'data': Array<HmnzTelesalesEmployment>;
}>> {
    const rb = new RequestBuilder(this.rootUrl, TelesalesEmploymentResourceService.GetTelesalesEmploymentsPath, 'get');
    if (params) {
      rb.query('filters', params.filters, {});
      rb.query('limit_page_length', params.limit_page_length, {});
      rb.query('limit_start', params.limit_start, {});
      rb.query('order_by', params.order_by, {});
      rb.query('or_filters', params.or_filters, {});
      rb.query('fields', params.fields, {"style":"form"});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'data': Array<HmnzTelesalesEmployment>;
        }>;
      })
    );
  }

  /**
   * Get TelesalesEmployment resources.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTelesalesEmployments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTelesalesEmployments(
    params?: {
      filters?: string;
      limit_page_length?: number;
      limit_start?: number;
      order_by?: string;
      or_filters?: string;
      fields?: string;
    },
    context?: HttpContext
  ): Observable<{
'data': Array<HmnzTelesalesEmployment>;
}> {
    return this.getTelesalesEmployments$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data': Array<HmnzTelesalesEmployment>;
}>): {
'data': Array<HmnzTelesalesEmployment>;
} => r.body)
    );
  }

}
