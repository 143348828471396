// These constants are injected via webpack environment variables.
// You can add more variables in webpack.common.js or in profile specific webpack.<dev|prod>.js files.
// If you change the values in the webpack config files, you need to re run webpack to update the application

import {environment} from '../environments/environment';

export const DEBUG_INFO_ENABLED = false;
export const SERVER_API_URL = environment.backend;
export const SERVER_NEW_API_URL = environment.newBackend;
export const EncryptedCode = 'HaNbEE3$Encrypt#Code';
export const DATATABLES_LANGUAGE = {
                'zeroRecords': '',
                'sProcessing': 'ثانية بس بجيب الداتا',
                'sLengthMenu': 'إعرض &nbsp;  _MENU_ &nbsp; نتايج ',
                'sZeroRecords': '',
                'sInfo': 'إظهار _START_ إلى _END_ من أصل _TOTAL_ صف',
                'sInfoEmpty': 'يعرض 0 إلى 0 من أصل 0 صف',
                'sInfoFiltered': '(من مجموع _MAX_ صفوف)',
                'sInfoPostFix': '',
                'sSearch': 'دور:',
                'sUrl': '',
                'oPaginate': {
                    'sFirst': '<<',
                    'sPrevious': '<',
                    'sNext': '>',
                    'sLast': '>>'
                }
            };

export const ProjectName = {
    ar : 'هنبيع',
    en : 'Hanbee3'
};

export const RegisterAs = {
    COMPANY : 'Company',
    SALES_PERSON : 'Salesperson',
    CALL_CENTER : 'CallCenter'
};

export const ProductStatus = {
    OFFLINE: 'OFFLINE',
    ONLINE: 'ONLINE',
    PENDING_APPROVAL: 'PENDING_APPROVAL'
};

export const GUILocalization = {
    'translation': {
        "sourceEmpty": "مفيش داتا",
        "pagingItemsPerPage": "الداتا المعروضه للصفحة :   ",
        "pagingOf": "من",
        "pagingNextPage": "اللي بعده",
        "pagingPrevPage": "ارجع",
        "pagingNoItems": "مفيش داتا",
        "infoPanelShowing": "عرض",
        "infoPanelItems": "داتا",
        "infoPanelOutOf": "من",
        "infoPanelThemeMangerTooltipText": "اعدادات الثيم",
        "infoPanelColumnManagerTooltipText": "اعدادات العمود",
        "infoPanelInfoTooltipText": "معلومات",
        "themeManagerModalTitle": "اعدادات الثيم",
        "themeManagerModalTheme": "الثيم:",
        "themeManagerModalRowColoring": "تلوين الصف:",
        "themeManagerModalVerticalGrid": "عرض بشكل عمودى",
        "themeManagerModalHorizontalGrid": "عرض بشكل افقى",
        "columnManagerModalTitle": "اعدادات العمود",
        "headerMenuMainTab": "المنيو",
        "headerMenuMainTabColumnSort": "ترتيب الاعمدة",
        "headerMenuMainTabHideColumn": "اخفى العمود",
        "headerMenuMainTabHighlightColumn": "تحديد ك مهم",
        "headerMenuMainTabMoveLeft": "حرك يمين",
        "headerMenuMainTabMoveRight": "حرك شمال",
        "headerMenuMainTabColumnSortAscending": "ترتيب تصاعدى",
        "headerMenuMainTabColumnSortDescending": "ترتيب تنازاى",
        "headerMenuMainTabColumnSortNone": "إلغى",
        "headerMenuFilterTab": "فيلتر",
        "headerMenuColumnsTab": "اعمده",
        "summariesCount": "عداد",
        "summariesDist": "توزيع",
        "summariesSum": "مجموع",
        "summariesAvg": "متوسط",
        "summariesMin": "الأقل",
        "summariesMax": "الأكتر",
        "summariesMed": "المتوسط",
        "summariesTruthy": "Truthy",
        "summariesFalsy": "Falsy",
        "summariesDistinctValuesTooltip": "Distinct values",
        "summariesAverageTooltip": "متوسط",
        "summariesMinTooltip": "الأقل",
        "summariesMaxTooltip": "ألاكتر",
        "summariesMedTooltip": "المتوسط",
        "summariesCountTooltip": "الداتا المعروضة"
    }
}

export type rolesTypes = 'hmnz_r_telesales_company' | 'hmnz_r_telesales_telesales' | 'hmnz_r_telesales_callcenter';

export const Roles = {
    COMPANY: 'hmnz_r_telesales_company',
    SALES: 'hmnz_r_telesales_telesales',
    CALL_CENTER: 'hmnz_r_telesales_callcenter'
}

export const Currencies = [
  {
    id: 'EGP',
    title: 'جنيه',
    country: 'مصر'
  },
  {
    id: 'SAR',
    title: 'ريال سعودى',
    country: 'المملكة العربية السعودية'
  },
  {
    id: 'QAR',
    title: 'ريال قطرى'
  }
];

export const CallCountries = [
    {
      id: 'EG',
      title: 'مصر'
    },
    {
      id: 'KSA',
      title: 'المملكة العربية السعودية'
    }
];

export const CallsReasons = [
  {
    name: 'Sales',
    title: 'مبيعات'
  },
  {
    name: 'Leads',
    title: 'لم معلومات'
  }
];

export const LeadsSources = [
  {
    name: 'MNO',
    title: 'أرقامى بس'
  },
  {
    name: 'MN_TSN',
    title: 'أرقامى وأرقام التيلي سيلز'
  },
  {
    name: 'TSNO',
    title: 'أرقام التيلي سيلز بس'
  }
];

export const SalesLeadsSources = [
  {
    name: 'MNO',
    title: 'أرقام الشركة بس'
  },
  {
    name: 'MN_TSN',
    title: 'أرقامى وأرقام الشركه'
  },
  {
    name: 'TSNO',
    title: 'أرقامى بس'
  }
];

export const Colors = {
  purchased: '#23a486',
  busy: '#FFCD39',
  followUp: '#50C82E',
  answered: '#0C76CF',
  notPickup: '#FF7272', 
  notInterested: '#ED8E00',
  outOfService: '#DC3545',
  wrongNumber: '#FFE951',
  closed: "#FFCD39"
}
