/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';


@Injectable({ providedIn: 'root' })
export class AuthService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `telesalesAuthCreateMyUsername()` */
  static readonly TelesalesAuthCreateMyUsernamePath = '/api/method/telesales.auth.create_my_username';

  /**
   * Invoke the telesales.auth.create_my_username method.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `telesalesAuthCreateMyUsername()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  telesalesAuthCreateMyUsername$Response(
    params: {

    /**
     * Your Username
     */
      username: string;
      body?: any
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, AuthService.TelesalesAuthCreateMyUsernamePath, 'post');
    if (params) {
      rb.query('username', params.username, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Invoke the telesales.auth.create_my_username method.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `telesalesAuthCreateMyUsername$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  telesalesAuthCreateMyUsername(
    params: {

    /**
     * Your Username
     */
      username: string;
      body?: any
    },
    context?: HttpContext
  ): Observable<any> {
    return this.telesalesAuthCreateMyUsername$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `telesalesAuthValidateUser()` */
  static readonly TelesalesAuthValidateUserPath = '/api/method/telesales.auth.validate_user';

  /**
   * Invoke the telesales.auth.validate_user method.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `telesalesAuthValidateUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  telesalesAuthValidateUser$Response(
    params: {

    /**
     * Username
     */
      usr: string;

    /**
     * Password
     */
      password: string;
      body?: any
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, AuthService.TelesalesAuthValidateUserPath, 'post');
    if (params) {
      rb.query('usr', params.usr, {});
      rb.query('password', params.password, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Invoke the telesales.auth.validate_user method.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `telesalesAuthValidateUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  telesalesAuthValidateUser(
    params: {

    /**
     * Username
     */
      usr: string;

    /**
     * Password
     */
      password: string;
      body?: any
    },
    context?: HttpContext
  ): Observable<any> {
    return this.telesalesAuthValidateUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `telesalesAuthRegister()` */
  static readonly TelesalesAuthRegisterPath = '/api/method/telesales.auth.register';

  /**
   * Invoke the telesales.auth.register method.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `telesalesAuthRegister()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  telesalesAuthRegister$Response(
    params: {

    /**
     * First Name
     */
      first_name: string;

    /**
     * Last Name
     */
      last_name: string;

    /**
     * Email
     */
      email: string;

    /**
     * Role
     */
      reg_as: string;

    /**
     * Phne
     */
      phone: string;

    /**
     * Location
     */
      location: string;

    /**
     * Experience
     */
      experiance: string;

    /**
     * Need Training
     */
      need_training: string;

    /**
     * Reference Code
     */
      ref_code: string;

    /**
     * User Name
     */
      user_name: string;
      body?: any
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, AuthService.TelesalesAuthRegisterPath, 'post');
    if (params) {
      rb.query('first_name', params.first_name, {});
      rb.query('last_name', params.last_name, {});
      rb.query('email', params.email, {});
      rb.query('reg_as', params.reg_as, {});
      rb.query('phone', params.phone, {});
      rb.query('location', params.location, {});
      rb.query('experiance', params.experiance, {});
      rb.query('need_training', params.need_training, {});
      rb.query('ref_code', params.ref_code, {});
      rb.query('user_name', params.user_name, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Invoke the telesales.auth.register method.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `telesalesAuthRegister$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  telesalesAuthRegister(
    params: {

    /**
     * First Name
     */
      first_name: string;

    /**
     * Last Name
     */
      last_name: string;

    /**
     * Email
     */
      email: string;

    /**
     * Role
     */
      reg_as: string;

    /**
     * Phne
     */
      phone: string;

    /**
     * Location
     */
      location: string;

    /**
     * Experience
     */
      experiance: string;

    /**
     * Need Training
     */
      need_training: string;

    /**
     * Reference Code
     */
      ref_code: string;

    /**
     * User Name
     */
      user_name: string;
      body?: any
    },
    context?: HttpContext
  ): Observable<any> {
    return this.telesalesAuthRegister$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `homainsDevopsAuthUpdateProfile()` */
  static readonly HomainsDevopsAuthUpdateProfilePath = '/api/method/homains_devops.auth.update_profile';

  /**
   * Invoke the homains_devops.auth.update_profile method.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `homainsDevopsAuthUpdateProfile()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  homainsDevopsAuthUpdateProfile$Response(
    params: {

    /**
     * First Name
     */
      first_name: string;

    /**
     * Middle Name
     */
      middle_name: string;

    /**
     * Last Name
     */
      last_name: string;

    /**
     * Mobile Number
     */
      mobile_no: string;

    /**
     * Gneder (Male / Female)
     */
      gender: string;

    /**
     * Birth Date
     */
      birth_date: string;

    /**
     * The Image Path
     */
      user_image: string;

    /**
     * Language (ar / en)
     */
      language: string;

    /**
     * TimeZone
     */
      time_zone: string;
      body?: any
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, AuthService.HomainsDevopsAuthUpdateProfilePath, 'post');
    if (params) {
      rb.query('first_name', params.first_name, {});
      rb.query('middle_name', params.middle_name, {});
      rb.query('last_name', params.last_name, {});
      rb.query('mobile_no', params.mobile_no, {});
      rb.query('gender', params.gender, {});
      rb.query('birth_date', params.birth_date, {});
      rb.query('user_image', params.user_image, {});
      rb.query('language', params.language, {});
      rb.query('time_zone', params.time_zone, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Invoke the homains_devops.auth.update_profile method.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `homainsDevopsAuthUpdateProfile$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  homainsDevopsAuthUpdateProfile(
    params: {

    /**
     * First Name
     */
      first_name: string;

    /**
     * Middle Name
     */
      middle_name: string;

    /**
     * Last Name
     */
      last_name: string;

    /**
     * Mobile Number
     */
      mobile_no: string;

    /**
     * Gneder (Male / Female)
     */
      gender: string;

    /**
     * Birth Date
     */
      birth_date: string;

    /**
     * The Image Path
     */
      user_image: string;

    /**
     * Language (ar / en)
     */
      language: string;

    /**
     * TimeZone
     */
      time_zone: string;
      body?: any
    },
    context?: HttpContext
  ): Observable<any> {
    return this.homainsDevopsAuthUpdateProfile$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `homainsDevopsAuthChangePassword()` */
  static readonly HomainsDevopsAuthChangePasswordPath = '/api/method/homains_devops.auth.change_password';

  /**
   * Invoke the homains_devops.auth.change_password method.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `homainsDevopsAuthChangePassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  homainsDevopsAuthChangePassword$Response(
    params: {

    /**
     * Old Password
     */
      old_password: string;

    /**
     * New Password
     */
      new_password: string;
      body?: any
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, AuthService.HomainsDevopsAuthChangePasswordPath, 'post');
    if (params) {
      rb.query('old_password', params.old_password, {});
      rb.query('new_password', params.new_password, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Invoke the homains_devops.auth.change_password method.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `homainsDevopsAuthChangePassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  homainsDevopsAuthChangePassword(
    params: {

    /**
     * Old Password
     */
      old_password: string;

    /**
     * New Password
     */
      new_password: string;
      body?: any
    },
    context?: HttpContext
  ): Observable<any> {
    return this.homainsDevopsAuthChangePassword$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

}
