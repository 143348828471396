import { Injectable } from '@angular/core';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { CookieService } from 'ngx-cookie';
import { AccountService } from './account.service';
import { Router } from '@angular/router';
import { TelesalesRevenueService } from '../../../../core/Backend/custom-backend/services/telesales-wallet.service';
import { CompanyInfoService } from '../../../../core/Backend/custom-backend/services/company-wallet.service';
import { AuthService } from '../../../../core/Backend/custom-backend/services/auth.service';

@Injectable({ providedIn: 'root' })
export class AuthServerProvider {

  constructor(
    private telesalesRevenueService: TelesalesRevenueService,
    private companyInfoService: CompanyInfoService,
    private $localStorage: LocalStorageService,
    private $sessionStorage: SessionStorageService,
    private cookieService: CookieService,
    private authService: AuthService,
    private accountService: AccountService,
    private router: Router) { }

  getToken(): string {
    return this.$sessionStorage.retrieve('authenticationtoken') || this.$localStorage.retrieve('authenticationtoken');
  }

  clearToken(): void {
    this.$localStorage.clear('authenticationtoken');
    this.$sessionStorage.clear('authenticationtoken');
    this.cookieService.removeAll();
  }

  storeAuthenticationToken(token): void {
    this.$localStorage.store('authenticationtoken', token);
    this.$sessionStorage.store('authenticationtoken', token);
  }

  logout() {
    this.authService.logout().subscribe(() => {
      this.companyInfoService.clearCompanyInfo();
      this.telesalesRevenueService.clearTelesalesRevenue();
      this.accountService.authenticate(null);
      this.clearToken();
      this.$localStorage.clear('authenticationtoken');
      this.$sessionStorage.clear('authenticationtoken');
      this.$localStorage.clear('matrixUser')
      this.cookieService.removeAll();
      this.router.navigate(['/log-in']);
    });
  }
}
