/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';


@Injectable({ providedIn: 'root' })
export class CompanyService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `telesalesCompanyAcceptJob()` */
  static readonly TelesalesCompanyAcceptJobPath = '/api/method/telesales.company.accept_job';

  /**
   * Invoke the telesales.company.accept_job method.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `telesalesCompanyAcceptJob()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  telesalesCompanyAcceptJob$Response(
    params: {

    /**
     * Job Name
     */
      job_id: string;
      body?: any
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, CompanyService.TelesalesCompanyAcceptJobPath, 'post');
    if (params) {
      rb.query('job_id', params.job_id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Invoke the telesales.company.accept_job method.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `telesalesCompanyAcceptJob$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  telesalesCompanyAcceptJob(
    params: {

    /**
     * Job Name
     */
      job_id: string;
      body?: any
    },
    context?: HttpContext
  ): Observable<any> {
    return this.telesalesCompanyAcceptJob$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `telesalesCompanyCrmSync()` */
  static readonly TelesalesCompanyCrmSyncPath = '/api/method/telesales.company.crm_sync';

  /**
   * Invoke the telesales.company.crm_sync method.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `telesalesCompanyCrmSync()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  telesalesCompanyCrmSync$Response(
    params?: {
      body?: any
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, CompanyService.TelesalesCompanyCrmSyncPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Invoke the telesales.company.crm_sync method.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `telesalesCompanyCrmSync$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  telesalesCompanyCrmSync(
    params?: {
      body?: any
    },
    context?: HttpContext
  ): Observable<any> {
    return this.telesalesCompanyCrmSync$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `telesalesCompanyRejectJob()` */
  static readonly TelesalesCompanyRejectJobPath = '/api/method/telesales.company.reject_job';

  /**
   * Invoke the telesales.company.reject_job method.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `telesalesCompanyRejectJob()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  telesalesCompanyRejectJob$Response(
    params: {

    /**
     * Job Name
     */
      job_id: string;

    /**
     * Rejection Reason
     */
      comments: string;
      body?: any
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, CompanyService.TelesalesCompanyRejectJobPath, 'post');
    if (params) {
      rb.query('job_id', params.job_id, {});
      rb.query('comments', params.comments, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Invoke the telesales.company.reject_job method.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `telesalesCompanyRejectJob$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  telesalesCompanyRejectJob(
    params: {

    /**
     * Job Name
     */
      job_id: string;

    /**
     * Rejection Reason
     */
      comments: string;
      body?: any
    },
    context?: HttpContext
  ): Observable<any> {
    return this.telesalesCompanyRejectJob$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `telesalesCompanyStopProduct()` */
  static readonly TelesalesCompanyStopProductPath = '/api/method/telesales.company.stop_product';

  /**
   * Invoke the telesales.company.stop_product method.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `telesalesCompanyStopProduct()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  telesalesCompanyStopProduct$Response(
    params: {

    /**
     * Item Id
     */
      item: string;
      body?: any
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, CompanyService.TelesalesCompanyStopProductPath, 'post');
    if (params) {
      rb.query('item', params.item, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Invoke the telesales.company.stop_product method.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `telesalesCompanyStopProduct$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  telesalesCompanyStopProduct(
    params: {

    /**
     * Item Id
     */
      item: string;
      body?: any
    },
    context?: HttpContext
  ): Observable<any> {
    return this.telesalesCompanyStopProduct$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `telesalesCompanyStopTelesales()` */
  static readonly TelesalesCompanyStopTelesalesPath = '/api/method/telesales.company.stop_telesales';

  /**
   * Invoke the telesales.company.stop_telesales method.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `telesalesCompanyStopTelesales()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  telesalesCompanyStopTelesales$Response(
    params: {

    /**
     * Employemnt Id
     */
      employemntId: string;
      body?: any
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, CompanyService.TelesalesCompanyStopTelesalesPath, 'post');
    if (params) {
      rb.query('employemntId', params.employemntId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Invoke the telesales.company.stop_telesales method.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `telesalesCompanyStopTelesales$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  telesalesCompanyStopTelesales(
    params: {

    /**
     * Employemnt Id
     */
      employemntId: string;
      body?: any
    },
    context?: HttpContext
  ): Observable<any> {
    return this.telesalesCompanyStopTelesales$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `telesalesCompanyResumeTelesales()` */
  static readonly TelesalesCompanyResumeTelesalesPath = '/api/method/telesales.company.resume_telesales';

  /**
   * Invoke the telesales.company.resume_telesales method.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `telesalesCompanyResumeTelesales()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  telesalesCompanyResumeTelesales$Response(
    params: {

    /**
     * Employemnt Id
     */
      employemntId: string;
      body?: any
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, CompanyService.TelesalesCompanyResumeTelesalesPath, 'post');
    if (params) {
      rb.query('employemntId', params.employemntId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Invoke the telesales.company.resume_telesales method.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `telesalesCompanyResumeTelesales$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  telesalesCompanyResumeTelesales(
    params: {

    /**
     * Employemnt Id
     */
      employemntId: string;
      body?: any
    },
    context?: HttpContext
  ): Observable<any> {
    return this.telesalesCompanyResumeTelesales$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `telesalesCompanyApplyCoupon()` */
  static readonly TelesalesCompanyApplyCouponPath = '/api/method/telesales.company.apply_coupon';

  /**
   * Invoke the telesales.company.apply_coupon method.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `telesalesCompanyApplyCoupon()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  telesalesCompanyApplyCoupon$Response(
    params: {

    /**
     * Coupon
     */
      coupon: string;

    /**
     * Item Name
     */
      item: string;
      body?: any
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, CompanyService.TelesalesCompanyApplyCouponPath, 'post');
    if (params) {
      rb.query('coupon', params.coupon, {});
      rb.query('item', params.item, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Invoke the telesales.company.apply_coupon method.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `telesalesCompanyApplyCoupon$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  telesalesCompanyApplyCoupon(
    params: {

    /**
     * Coupon
     */
      coupon: string;

    /**
     * Item Name
     */
      item: string;
      body?: any
    },
    context?: HttpContext
  ): Observable<any> {
    return this.telesalesCompanyApplyCoupon$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `telesalesCompanyAddPaymentMethod()` */
  static readonly TelesalesCompanyAddPaymentMethodPath = '/api/method/telesales.company.add_payment_method';

  /**
   * Invoke the telesales.company.add_payment_method method.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `telesalesCompanyAddPaymentMethod()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  telesalesCompanyAddPaymentMethod$Response(
    params?: {
      body?: any
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, CompanyService.TelesalesCompanyAddPaymentMethodPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Invoke the telesales.company.add_payment_method method.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `telesalesCompanyAddPaymentMethod$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  telesalesCompanyAddPaymentMethod(
    params?: {
      body?: any
    },
    context?: HttpContext
  ): Observable<any> {
    return this.telesalesCompanyAddPaymentMethod$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `telesalesCompanyEditSegments()` */
  static readonly TelesalesCompanyEditSegmentsPath = '/api/method/telesales.company.edit_segments';

  /**
   * Invoke the telesales.company.edit_segments method.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `telesalesCompanyEditSegments()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  telesalesCompanyEditSegments$Response(
    params: {

    /**
     * Item Id
     */
      item: string;

    /**
     * Segments Comma Separated
     */
      segments: string;
      body?: any
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, CompanyService.TelesalesCompanyEditSegmentsPath, 'post');
    if (params) {
      rb.query('item', params.item, {});
      rb.query('segments', params.segments, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Invoke the telesales.company.edit_segments method.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `telesalesCompanyEditSegments$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  telesalesCompanyEditSegments(
    params: {

    /**
     * Item Id
     */
      item: string;

    /**
     * Segments Comma Separated
     */
      segments: string;
      body?: any
    },
    context?: HttpContext
  ): Observable<any> {
    return this.telesalesCompanyEditSegments$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `telesalesCompanyRemainingCalls()` */
  static readonly TelesalesCompanyRemainingCallsPath = '/api/method/telesales.company.remaining_calls';

  /**
   * Invoke the telesales.company.remaining_calls method.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `telesalesCompanyRemainingCalls()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  telesalesCompanyRemainingCalls$Response(
    params: {

    /**
     * Item Id
     */
      item: string;
      body?: any
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, CompanyService.TelesalesCompanyRemainingCallsPath, 'post');
    if (params) {
      rb.query('item', params.item, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Invoke the telesales.company.remaining_calls method.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `telesalesCompanyRemainingCalls$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  telesalesCompanyRemainingCalls(
    params: {

    /**
     * Item Id
     */
      item: string;
      body?: any
    },
    context?: HttpContext
  ): Observable<any> {
    return this.telesalesCompanyRemainingCalls$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `telesalesCompanyPurchased()` */
  static readonly TelesalesCompanyPurchasedPath = '/api/method/telesales.company.purchased';

  /**
   * Invoke the telesales.company.purchased method.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `telesalesCompanyPurchased()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  telesalesCompanyPurchased$Response(
    params: {

    /**
     * Contact
     */
      contact: string;

    /**
     * Item
     */
      item: string;

    /**
     * Telesales
     */
      telesales: string;
      body?: any
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, CompanyService.TelesalesCompanyPurchasedPath, 'post');
    if (params) {
      rb.query('contact', params.contact, {});
      rb.query('item', params.item, {});
      rb.query('telesales', params.telesales, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Invoke the telesales.company.purchased method.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `telesalesCompanyPurchased$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  telesalesCompanyPurchased(
    params: {

    /**
     * Contact
     */
      contact: string;

    /**
     * Item
     */
      item: string;

    /**
     * Telesales
     */
      telesales: string;
      body?: any
    },
    context?: HttpContext
  ): Observable<any> {
    return this.telesalesCompanyPurchased$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `telesalesCompanyRefreshData()` */
  static readonly TelesalesCompanyRefreshDataPath = '/api/method/telesales.company.refresh_data';

  /**
   * Invoke the telesales.company.refresh_data method.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `telesalesCompanyRefreshData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  telesalesCompanyRefreshData$Response(
    params: {

    /**
     * Item
     */
      item: string;
      body?: any
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, CompanyService.TelesalesCompanyRefreshDataPath, 'post');
    if (params) {
      rb.query('item', params.item, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Invoke the telesales.company.refresh_data method.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `telesalesCompanyRefreshData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  telesalesCompanyRefreshData(
    params: {

    /**
     * Item
     */
      item: string;
      body?: any
    },
    context?: HttpContext
  ): Observable<any> {
    return this.telesalesCompanyRefreshData$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `telesalesCompanyMyBill()` */
  static readonly TelesalesCompanyMyBillPath = '/api/method/telesales.company.my_bill';

  /**
   * Invoke the telesales.company.my_bill method.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `telesalesCompanyMyBill()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  telesalesCompanyMyBill$Response(
    params?: {
      body?: any
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, CompanyService.TelesalesCompanyMyBillPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Invoke the telesales.company.my_bill method.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `telesalesCompanyMyBill$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  telesalesCompanyMyBill(
    params?: {
      body?: any
    },
    context?: HttpContext
  ): Observable<any> {
    return this.telesalesCompanyMyBill$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `telesalesCompanyGenerateApiKey()` */
  static readonly TelesalesCompanyGenerateApiKeyPath = '/api/method/telesales.company.generate_api_key';

  /**
   * Invoke the telesales.company.generate_api_key method.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `telesalesCompanyGenerateApiKey()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  telesalesCompanyGenerateApiKey$Response(
    params?: {
      body?: any
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, CompanyService.TelesalesCompanyGenerateApiKeyPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Invoke the telesales.company.generate_api_key method.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `telesalesCompanyGenerateApiKey$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  telesalesCompanyGenerateApiKey(
    params?: {
      body?: any
    },
    context?: HttpContext
  ): Observable<any> {
    return this.telesalesCompanyGenerateApiKey$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `telesalesCompanyUpdateLimitBudget()` */
  static readonly TelesalesCompanyUpdateLimitBudgetPath = '/api/method/telesales.company.update_limit_budget';

  /**
   * Invoke the telesales.company.update_limit_budget method.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `telesalesCompanyUpdateLimitBudget()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  telesalesCompanyUpdateLimitBudget$Response(
    params: {

    /**
     * New Amount
     */
      new_amount: string;

    /**
     * Item Name
     */
      item: string;

    /**
     * Boolean
     */
      reset: string;
      body?: any
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, CompanyService.TelesalesCompanyUpdateLimitBudgetPath, 'post');
    if (params) {
      rb.query('new_amount', params.new_amount, {});
      rb.query('item', params.item, {});
      rb.query('reset', params.reset, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Invoke the telesales.company.update_limit_budget method.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `telesalesCompanyUpdateLimitBudget$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  telesalesCompanyUpdateLimitBudget(
    params: {

    /**
     * New Amount
     */
      new_amount: string;

    /**
     * Item Name
     */
      item: string;

    /**
     * Boolean
     */
      reset: string;
      body?: any
    },
    context?: HttpContext
  ): Observable<any> {
    return this.telesalesCompanyUpdateLimitBudget$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `telesalesCompanyAvailableCalls()` */
  static readonly TelesalesCompanyAvailableCallsPath = '/api/method/telesales.company.available_calls';

  /**
   * Invoke the telesales.company.available_calls method.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `telesalesCompanyAvailableCalls()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  telesalesCompanyAvailableCalls$Response(
    params: {

    /**
     * Item Id
     */
      item: string;
      body?: any
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, CompanyService.TelesalesCompanyAvailableCallsPath, 'post');
    if (params) {
      rb.query('item', params.item, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Invoke the telesales.company.available_calls method.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `telesalesCompanyAvailableCalls$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  telesalesCompanyAvailableCalls(
    params: {

    /**
     * Item Id
     */
      item: string;
      body?: any
    },
    context?: HttpContext
  ): Observable<any> {
    return this.telesalesCompanyAvailableCalls$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `telesalesCompanyDeleteLead()` */
  static readonly TelesalesCompanyDeleteLeadPath = '/api/method/telesales.company.delete_lead';

  /**
   * Invoke the telesales.company.delete_lead method.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `telesalesCompanyDeleteLead()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  telesalesCompanyDeleteLead$Response(
    params: {

    /**
     * LeadID
     */
      name: string;
      body?: any
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, CompanyService.TelesalesCompanyDeleteLeadPath, 'post');
    if (params) {
      rb.query('name', params.name, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Invoke the telesales.company.delete_lead method.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `telesalesCompanyDeleteLead$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  telesalesCompanyDeleteLead(
    params: {

    /**
     * LeadID
     */
      name: string;
      body?: any
    },
    context?: HttpContext
  ): Observable<any> {
    return this.telesalesCompanyDeleteLead$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `telesalesCompanyDefaultMinutePrice()` */
  static readonly TelesalesCompanyDefaultMinutePricePath = '/api/method/telesales.company.default_minute_price';

  /**
   * Invoke the telesales.company.default_minute_price method.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `telesalesCompanyDefaultMinutePrice()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  telesalesCompanyDefaultMinutePrice$Response(
    params?: {
      body?: any
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, CompanyService.TelesalesCompanyDefaultMinutePricePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Invoke the telesales.company.default_minute_price method.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `telesalesCompanyDefaultMinutePrice$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  telesalesCompanyDefaultMinutePrice(
    params?: {
      body?: any
    },
    context?: HttpContext
  ): Observable<any> {
    return this.telesalesCompanyDefaultMinutePrice$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

}
